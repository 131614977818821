<template>
  <textarea ref="textarea" v-text="modelValue"></textarea>
</template>

<style lang="scss">
.CodeMirror {
  height: 100%;
  width: 100%;
}
</style>


<script>
import CodeMirror from 'codemirror/lib/codemirror.js';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/dracula.css';
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/addon/scroll/simplescrollbars.js';
import 'codemirror/addon/scroll/simplescrollbars.css';

export default {
  name: 'Editor',
  props: ['modelValue'],
  data() {
    return {
      editor: null,
    };
  },
  mounted() {
    this.editor = CodeMirror.fromTextArea(this.$refs.textarea, {
      theme: 'dracula',
      mode: 'text/javascript',
      lineNumbers: true,
      scrollbarStyle: 'simple',
    });
    this.editor.on('change', this.onChange);
  },
  methods: {
    onChange(e) {
      this.$emit('update:modelValue', e.getValue());
    },
  },
}
</script>
