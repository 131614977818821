<template>
  <div class="container">
    <div class="input">
      <input type="number" v-model="temperature" placeholder="Temperature"/>
      <input type="number" v-model="alpha" min="0" max="1" step="0.000001" placeholder="Alpha"/>
      <input type="checkbox" id="show-pattern" v-model="showPattern">
      <label for="show-pattern">Show pattern</label>
      <input type="file" ref="file" accept="image/bmp,image/jpeg,image/png">
      <button @click="start" v-if="stopped">Start</button>
      <button @click="stop" v-else>Stop</button>
      Status: {{ status }}
      <button @click="download">Download</button>
    </div>
    <div class="content">
      <div class="editor">
        <Editor v-model="model"/>
      </div>
      <div class="viewer">
        <div class="canvas">
          <canvas width="100" height="100" ref="sourceCanvas"></canvas>
        </div>
        <div class="canvas">
          <canvas width="100" height="100" ref="destinationCanvas"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
html, body, #app {
  margin: 0;
  padding: 0;
  border: none;
  position: relative;
  width: 100%;
  height: 100%;
}

.container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.viewer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 50%;
}

.content {
  flex-grow: 1;
  display: flex;
}

.editor {
  flex-grow: 1;
  width: 50%;
}

.canvas {
  flex-grow: 1;
}

canvas {
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
</style>


<script>
import Editor from './Editor';
import ScriptWorker from 'worker-loader!./app.worker';

export default {
  name: 'App',
  components: {
    Editor,
  },
  data() {
    return {
      model: "new ModelClass([[0, 0, 0], [255, 255, 255]], ({ selected, data }) => {\n" +
          "    let total = 0;\n" +
          "    for (let i = 0; i < 3; ++i)\n" +
          "        total += Math.abs(selected[i] - data[i]);\n" +
          "    return total;\n" +
          "}, [\n" +
          "    [-2, 0], [1, 0], [0, -2], [0, 1],\n" +
          "], [\n" +
          "    ({ selected, neighbor }) => 50 * (selected !== neighbor ? 0 : 1),\n" +
          "    ({ selected, neighbor }) => 50 * (selected !== neighbor ? 0 : 1),\n" +
          "    ({ selected, neighbor }) => 50 * (selected === neighbor ? 0 : 1),\n" +
          "    ({ selected, neighbor }) => 50 * (selected === neighbor ? 0 : 1),\n" +
          "])",
      status: 'Waiting for image...',
      alpha: 0.9999,
      temperature: 51,
      stopped: true,
      showPattern: false,

      source: {
        type: null,
        name: null,
        canvas: null,
        context: null,
        data: null,
      },
      destination: {
        canvas: null,
        offscreen: null,
      },
      worker: null,
      colors: [
        [0, 0, 0],
        [255, 255, 255],
      ]
    };
  },
  mounted() {
    this.source.canvas = this.$refs.sourceCanvas;
    this.source.context = this.source.canvas.getContext('2d');
    this.destination.canvas = this.$refs.destinationCanvas;
    this.destination.offscreen = this.destination.canvas.transferControlToOffscreen();
    this.worker = new ScriptWorker();
    this.worker.postMessage({
      fun: 'setup',
      args: { canvas: this.destination.offscreen }
    }, [this.destination.offscreen]);

    const funs = {
      'updateStatus': this.updateStatus,
      'setStopped': this.setStopped,
    }

    this.worker.onmessage = (e) => funs[e.data.fun](e.data.args);
  },
  beforeUnmount() {
    if (this.worker)
      this.worker.terminate();
  },
  methods: {
    setStopped() {
      this.stopped = true;
    },
    updateStatus({ status }) {
      this.status = status;
    },
    stop() {
      this.worker.postMessage({ fun: 'stop' });
    },
    start() {
      if (this.$refs.file.files.length) {
        const file = this.$refs.file.files[0];

        this.stopped = false;
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target.readyState === FileReader.DONE) {
            const image = new Image();
            image.src = e.target.result;
            image.onload = () => this.updateCanvas(file.name, file.type, image);
          }
        }
        reader.readAsDataURL(file);
      }
    },
    updateCanvas(name, type, image) {
      this.source.name = name;
      this.source.type = type;
      this.source.canvas.width = image.width;
      this.source.canvas.height = image.height;
      this.destination.canvas.setAttribute('width', image.width);
      this.destination.canvas.setAttribute('height', image.height);
      this.source.context.drawImage(image, 0, 0);
      this.source.data = this.source.context.getImageData(0, 0, image.width, image.height);
      this.worker.postMessage({
        fun: 'init',
        args: {
          width: image.width,
          height: image.height,
          sourceData: this.source.data,
          temperature: parseFloat(this.temperature),
          alpha: parseFloat(this.alpha),
          model: this.model,
          showPattern: this.showPattern,
        },
      });
    },
    download() {
      if (this.source.name) {
        const url = this.destination.canvas.toDataURL(this.source.type);
        const el = document.createElement('a');
        el.setAttribute('href', url);
        el.setAttribute('download', this.source.name);
        el.style.display = 'none';
        document.body.appendChild(el);
        el.click();
        document.body.removeChild(el);
      }
    },
  },
}
</script>
